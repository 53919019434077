module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"main":"#BA3D3B"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TECVİDLİ KURAN","short_name":"TECVİDLİ KURAN","start_url":"/","background_color":"#007d9f","theme_color":"#feffd2","display":"standalone","icon":"static/media/Images/faveicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b339fb28c1bcbd8bf53032088505cb22"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/p/*","/s/*","/pr/*"],"appendScript":"/home/ittadmin/gatsby/src/custom-sw-code.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
